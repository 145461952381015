<template>
  <VueTelInput
    v-model="value"
    class="base-tel-input"
    mode="international"
    :input-options="{ type: 'tel' }"
    valid-characters-only
    :auto-default-country="false"
    default-country="48"
    @on-input="handleInput"
    @country-changed="setActiveCountry"
  />
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
import { VueTelInput } from 'vue-tel-input'

type NumberObject = {
  country: { name: string; iso2: string; dialCode: string }
  countryCallingCode?: string
  countryCode?: string
  formatted: string
  nationalNumber?: string
  number?: string
  valid?: boolean
}

const { t } = useI18n()
const props = withDefaults(
  defineProps<{
    name: string
    placeholder?: string
    required?: boolean
  }>(),
  {
    placeholder: '',
    required: false
  }
)
const valid = ref<string | true>(true)
const { value } = useField(
  () => props.name,
  () => valid.value
)

const activeCountry = reactive({
  country: '',
  prefix: ''
})

const setActiveCountry = ({ iso2, dialCode }: NumberObject['country']) => {
  activeCountry.country = iso2
  activeCountry.prefix = `+${dialCode}`
}

const handleInput = (newValue: string, { valid: isValid }: NumberObject) => {
  valid.value = props.required
    ? !newValue.length
      ? t('form.required')
      : !!isValid || t('form.incorrect-phone')
    : !!isValid || !newValue.length || t('form.incorrect-phone')
}
</script>

<style lang="scss" scoped>
.base-tel-input {
  position: relative;
  padding: rem(12px) rem(16px);
}

:deep(.vti__dropdown) {
  display: none;
}

:deep(.vti__input) {
  width: 100%;
  font-family: $font;
  line-height: rem(24px);
  color: $beige;

  &::placeholder {
    color: $beige;
  }

  &:focus {
    outline: none;
  }

  &:-internal-autofill-selected {
    background-color: transparent;
    -webkit-text-fill-color: $beige;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: $beige;
  }
}
</style>
