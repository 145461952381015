<template>
  <Teleport to="body">
    <Transition name="fade">
      <div v-if="active" ref="popup" class="popup">
        <div class="popup-inner">
          <div ref="popupContent" class="content">
            <h3 class="title">{{ t('privacy-policy.title') }}</h3>
            <div
              class="description"
              v-html="nbsp(t('privacy-policy.content'))"
            />
            <div class="gradient" />
          </div>
          <button class="close" @click="close">
            <CloseIcon class="close-icon" />
          </button>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import CloseIcon from '@/assets/icons/close.svg?component'

const props = defineProps<{
  active: boolean
}>()

const emit = defineEmits(['close'])

function close() {
  emit('close')
}

const { t } = useI18n()

const { popup, popupContent } = usePopup({ active: () => props.active, close })
</script>

<style lang="scss" scoped>
$close-size: var(--close-size);

.popup {
  --close-size: #{rem(64px)};
  @include fill-space($position: fixed);
  inset: 0;
  z-index: z(popup);
  align-items: center;
  padding-top: $close-size;
  overflow-y: auto;
  background: $dark-brown-90;

  @include media-up(lg) {
    --close-size: #{rem(57px)};
  }
}

.popup-inner {
  @include media-up(lg) {
    position: absolute;
    top: 50%;
    left: calc(50% + var(--close-size));
    display: flex;
    transform: translateX(-50%) translateY(-50%);
  }
}

.content {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: rem(109px);
  padding-bottom: rem(80px);
  color: $dark-brown;
  background-color: $beige;

  @include media-up(md) {
    top: 50%;
    right: unset;
    bottom: unset;
    left: 50%;
    width: rem(640px);
    max-height: rem(715px);
    padding-top: rem(59px);
    margin: auto;
    margin-bottom: rem(54px);
    transform: translateY(-50%) translateX(-50%);
  }

  @include media-up(lg) {
    position: relative;
    left: 0;
    width: rem(764px);
    max-height: rem(441px);
    padding-bottom: rem(46px);
    margin: 0;
    transform: none;
  }
}

.close {
  @include size($close-size);
  position: fixed;
  top: 0;
  right: 0;
  z-index: z(popup) + 1;
  background: $orange;

  @include media-up(lg) {
    position: static;
  }
}

.close-icon {
  @include size(rem(18px));
  color: $beige;

  @include media-up(md) {
    @include size(rem(20px));
  }

  .contrast-mode & {
    color: $contrast-black;
  }
}

.text {
  position: relative;
}

.gradient {
  position: absolute;
  right: rem(26px);
  bottom: rem(80px);
  left: 0;
  flex-shrink: 0;
  height: rem(55px);
  background: linear-gradient(180deg, rgba(252, 246, 231, 0) 0%, $beige 100%);

  @include media-up(md) {
    right: rem(69px);
    bottom: rem(69px);
  }

  @include media-up(lg) {
    bottom: rem(46px);
  }
}

.title {
  @include font-size(
    (
      xs: 23px,
      md: 27px
    ),
    1.12
  );
  padding-inline: rem(28px);
  margin-bottom: rem(44px);
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.025em;

  @include media-up(md) {
    padding-inline: rem(58px);
    margin-bottom: rem(49px);
  }

  @include media-up(lg) {
    max-width: rem(525px);
    padding-inline: unset;
    margin-inline: auto;
    margin-bottom: rem(39px);
  }
}

.description {
  @include font-size(
    (
      xs: 16px,
      md: 18px
    ),
    1.34
  );
  padding-right: rem(8px);
  padding-bottom: rem(55px);
  padding-left: rem(25px);
  margin-right: rem(18px);
  overflow-y: auto;
  font-weight: 500;
  letter-spacing: -0.025em;

  @include media-up(md) {
    padding-right: rem(9px);
    padding-left: rem(60px);
    margin-right: rem(60px);
  }

  @include media-up(lg) {
    padding-right: rem(16px);
    padding-left: rem(69px);
    margin-right: ren(55px);
  }

  :deep(.bold) {
    font-weight: 600;
  }

  :deep(ol) {
    list-style-type: none;
    counter-reset: item;
  }

  :deep(ol li::before) {
    content: counters(item, '.') '. ';
    counter-increment: item;
  }

  :deep(ol ol) {
    padding-left: rem(14px);

    @include media-up(md) {
      padding-left: rem(16px);
    }

    @include media-up(lg) {
      padding-left: rem(29px);
    }
  }

  :deep(li) {
    margin-bottom: rem(21px);
  }

  @supports (not (selector(::-webkit-scrollbar))) {
    scrollbar-color: $dark-brown $dark-brown-30;
  }

  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar {
      width: rem(3px);
      border-radius: rem(1px);
    }

    &::-webkit-scrollbar-track {
      width: rem(3px);
      background-color: $dark-brown-30;
      background-clip: padding-box;
      border-radius: rem(1px);
    }

    &::-webkit-scrollbar-thumb {
      width: rem(3px);
      background-color: $dark-brown;
      border-radius: rem(1px);
    }
  }
}
</style>
